import React, {useEffect, useState} from 'react';
import {Button, Col, Collapse, Dropdown, Row, Spin} from "antd";
import SpotCyberThreat from "../components/SpotCyberThreat";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getSACCourses, updateSACAccessTracker} from "../../shared/rootActions";
import {DownloadOutlined, RightOutlined} from "@ant-design/icons";
import {SAC_DOWNLOAD_ASSETS_DEFAULT_TOPIC} from "../../shared/constants/globalConstants";
import {getUserToken} from "../../shared/helper";
import SACIntroIcon from '../../assets/images/sac_intro.png';
import SACLessonPackage1Icon from '../../assets/images/sac_lp_1.png';
import SACLessonPackage2Icon from '../../assets/images/sac_lp_2.png';
import i18next from "i18next";

const ASSETS_LANGUAGE_LABELS = {
    en: "English (UK)",
    en_au: "English (Australia)",
    en_ca: "English (Canada)",
    en_us: "English (US)",
    fr: "French",
    fr_ca: "French (Canada)",
    es: "Spanish (Europe)",
    es_mx: "Spanish",
    pt: "Portuguese (Portugal)",
    pt_br: "Portuguese (Brasil)",
    it: "Italian",
    de: "German",
    ja: "Japanese",
}

const SACAssetPage = () => {
    const {sac_categories, userStatusChecked} = useSelector((state) => state?.root || {});
    const {loading: sac_loading, course_categories} = sac_categories || {};

    const [downloadPackages, setDownloadPackages] = useState({});
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const supportedLang = ASSETS_LANGUAGE_LABELS;

    useEffect(() => {
        dispatch(getSACCourses());
    }, []);

    useEffect(() => {
        let tempPackages = {};
        if (sac_categories) {
            course_categories && course_categories?.map((category) => {
                let availableCourses = category?.sac_courses || [];

                availableCourses && availableCourses?.map((course) => {
                    // parse course to retrieve downloadable content
                    course?.content?.forEach((section) => {
                        if (section?.name === SAC_DOWNLOAD_ASSETS_DEFAULT_TOPIC) {
                            const packages = [];
                            section?.modules?.forEach((module) => {
                                if (module?.visible) {
                                    const lang = module?.name?.match(/\[([^\]]+)\]$/)?.[1];
                                    if (lang && supportedLang.hasOwnProperty(lang)) {
                                        packages.push({
                                            key: module.id,
                                            label: (
                                                <Row align="middle" gutter={24}>
                                                    <Col span={2}>
                                                        <DownloadOutlined/>
                                                    </Col>
                                                    <Col>
                                                        <span>{t(supportedLang[lang])}</span>
                                                    </Col>
                                                </Row>
                                            ),
                                            url: module?.contents[0]?.fileurl,
                                        })
                                    }
                                }
                            });
                            tempPackages = {
                                ...tempPackages,
                                [course.id]: packages
                            }
                        }
                    });
                });

                setDownloadPackages(tempPackages);
                //TODO: Sort languages alphabetically
            });
        }
    }, [course_categories]);

    const handleDownload = (e, id) => {
        const downloadItem = downloadPackages[id]?.find((item) => (item.key === +e.key));
        if (downloadItem?.url) {
            const url = new URL(downloadItem.url);
            url.searchParams.set('token', getUserToken());
            let downloadLink = document.createElement('a');
            downloadLink.target = '_blank';
            downloadLink.download = t('pageutilities_navbar_assets');
            downloadLink.href = url;
            downloadLink.click();
            downloadLink.remove();
            updateSACAccessTracker(id, downloadItem?.key);
        }
    };

    const downloadMenuProps = (id) => ({
        items: downloadPackages[id],
        onClick: (e) => handleDownload(e, id),
    });

    const getCategoryImage = (categoryID) => {
        switch (categoryID) {
            case 'sac_intro':
                return SACIntroIcon;
            case 'sac_lesson_package_1':
                return SACLessonPackage1Icon;
            default:
                return SACLessonPackage2Icon;
        }
    }

    const isCollapsible = (category, coursesCount) => {
        if (category?.idnumber === 'sac_intro') {
            let htmlObject = document.createElement('div');
            htmlObject.innerHTML = category?.description.trim();
            return Boolean(htmlObject.getElementsByClassName('multilang').length);
        }

        return coursesCount > 0;
    }

    return (
        <div className="main_page container">
            <section className="greeting">
                <h5 className="greeting_txt">{t('studentapp_sac_title')}</h5>
                <p className='greeting_desc'>
                    {t('studentapp_sac_desc')}
                </p>
                <div className="sac_banner_img"></div>
            </section>
            {
                sac_loading ? (
                    <Spin size={"large"}/>
                ) : userStatusChecked && course_categories && course_categories?.length > 0
                    ? (
                        <section className={"sac-content-section"}>
                            <div className={"page-controls"}>
                                {/*Controls for filters, sorting etc go here*/}
                            </div>
                            <div className="module-container">
                                <Collapse accordion
                                          className={'category-collapse'}>
                                    {course_categories?.map((category, id) => {
                                        let icon = getCategoryImage(category?.idnumber);
                                        let coursesCount = category?.sac_courses?.length || 0;
                                        let subtext = category?.idnumber === 'sac_intro' ? t('2 minute read', '2 minute read') : `${i18next.t('Lesson Packages', {count: coursesCount})}`;
                                        let collapsible = isCollapsible(category, coursesCount);

                                        return (
                                            <Collapse.Panel key={`panel-${category?.idnumber}`}
                                                            collapsible={collapsible ? '-' : 'icon'}
                                                            showArrow={collapsible}
                                                            header={<div className={'header_text'}>
                                                                <img src={icon}/>
                                                                <div>
                                                                    <h4>{t(category?.name, category?.name)}</h4>
                                                                    {subtext && <p>{subtext}</p>}
                                                                </div>
                                                            </div>}>
                                                {category?.idnumber === 'sac_intro' ? <div className={'sac_intro'}
                                                                                           dangerouslySetInnerHTML={{__html: category?.description}}></div>
                                                    : category?.sac_courses?.map((course) => {
                                                        const tagArray = course?.tag ? course.tag.split(",") : [];
                                                        return (
                                                            <div className={"module-wrapper"} key={course?.id}>
                                                                <div className={"module-content"}>
                                                                    <div className={"module-details"}>
                                                                        <h4>{t(course?.fullname, course?.fullname)}</h4>
                                                                        {tagArray?.length > 0 &&
                                                                        <div className={"module-tags"}>
                                                                            {tagArray.map((tag, id) => <span
                                                                                key={`${tag}-${id}`}
                                                                                className={"age"}>{t(tag.trim(), tag.trim())}</span>)}
                                                                        </div>}
                                                                        <div className="sac_course_summary"
                                                                             dangerouslySetInnerHTML={{__html: course?.summary}}></div>
                                                                    </div>
                                                                    <div className={"module-download"}>
                                                                        {
                                                                            (Object.keys(downloadPackages)?.length > 0) && (
                                                                                <Dropdown
                                                                                    menu={downloadMenuProps(course.id)}
                                                                                    disabled={!downloadPackages?.hasOwnProperty(course.id)}
                                                                                >
                                                                                    <Button
                                                                                        className={"sac-download-button"}><span><span>{t('studentapp_sac_course_button')}</span> <RightOutlined/></span></Button>
                                                                                </Dropdown>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Collapse.Panel>
                                        )
                                    })}
                                </Collapse>
                            </div>
                            <div className={'sac_banner_wrap'}>
                                <div className="left_icon"></div>
                                <div className="right_part">
                                    <h6>{t('studentapp_sac_sac_banner_title')}</h6>
                                    <p>{t('studentapp_sac_sac_banner_text')}</p>
                                </div>
                            </div>
                        </section>
                    ) :
                    (
                        <section className={"cyber_sec"}>
                            <h4>{t('homepage_feature')}</h4>
                            <SpotCyberThreat/>
                        </section>
                    )
            }
        </div>
    )
}

export default SACAssetPage;
