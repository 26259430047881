import {Form, Input, Checkbox, message} from "antd";
import React, {useEffect, useState} from "react";
import {TextEditor} from "../../RichTextEditor";
import {CaretDownOutlined, CaretRightOutlined, CopyOutlined, DownOutlined, RightOutlined} from "@ant-design/icons";
import Button from "react-rte/lib/ui/Button";
import {useSelector} from "react-redux";

export const Notification = ({form, actionId = 0}) => {
    const CheckboxGroup = Checkbox.Group;
    const [showPlaceholders, setShowPlaceholders] = useState(false);
    const [copied, setCopied] = useState(false);
    const {remediationData} = useSelector((state) => state?.remediation);

    const inputItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
        },
    };

    const noLabelItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 20,
                offset: 4,
            },
        },
    };

    const sendToOptions = [
        {
            value: 'matching',
            label: 'Matching Users'
        },
        {
            value: 'manager',
            label: 'Manager',
        }
    ];

    useEffect(() => {
        if (copied) {
            message.destroy();
            message.success('Text copied to clipboard');
            setCopied(false);
        }
    }, [copied])

    const handlePlaceholderCopy = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
    }

    const placeholderOptions = () => (
        <div className={'placeholder-content'}>
            <p>{`${remediationData?.site_link} - Site link`}
                <Button onClick={() => handlePlaceholderCopy(`${remediationData?.site_link}`)}><CopyOutlined/></Button>
            </p>
            <p>{`{{userfirstname}} - First name`}
                <Button onClick={() => handlePlaceholderCopy('{{userfirstname}}')}><CopyOutlined/></Button>
            </p>
            <p>{`{{userfullname}} - Full name`}
                <Button onClick={() => handlePlaceholderCopy('{{userfullname}}')}><CopyOutlined/></Button>
            </p>
            <p>{`{{certificationname}} - Certification name`}
                <Button onClick={() => handlePlaceholderCopy('{{certificationname}}')}><CopyOutlined/></Button>
            </p>
            <p>{`{{certificationduedate}} - Certification due date`}
                <Button onClick={() => handlePlaceholderCopy('{{certificationduedate}}')}><CopyOutlined/></Button>
            </p>
        </div>
    )

    return (
        <div key={actionId} className={'condition-input-item-content action-notification'}>
            <Form.Item name={[`notification_${actionId}`, "action_subject"]}
                       fieldKey={[actionId, 'action_subject']}
                       rules={[{required: true, message: "Email subject is required"}]}
                       {...inputItemLayout}
                       label={'Subject'}>
                <Input placeholder={'Enter subject here...'}/>
            </Form.Item>
            <Form.Item name={[`notification_${actionId}`, "action_body"]}
                       fieldKey={[actionId, 'action_body']}
                       rules={[
                           {required: true, message: "Email body is required"},
                           {
                               validator: async (_, value) => {
                                   if (value) {
                                       const temp = document.createElement("div");
                                       temp.innerHTML = value;
                                       if (!temp.textContent.trim()) {
                                           throw new Error('Email body is required');
                                       }

                                   }
                               },
                           }
                       ]}
                       {...inputItemLayout}
                       label={'Body'}
                       initialValue={''}>
                <TextEditor/>
            </Form.Item>
            <Form.Item {...noLabelItemLayout}>
                <Button
                    className={'placeholder-btn'}
                    onClick={() => setShowPlaceholders(!showPlaceholders)}> {showPlaceholders ? <CaretDownOutlined/> :
                    <CaretRightOutlined/>} Available placeholders</Button>
                {showPlaceholders && placeholderOptions()}
            </Form.Item>
            <Form.Item name={[`notification_${actionId}`, "action_sendto"]}
                       fieldKey={[actionId, 'action_sendto']}
                       rules={[{required: true, message: "Email recipient is required"}]}
                       {...inputItemLayout}
                       label={'Send to'}>
                <CheckboxGroup className={'notification-checkbox'}
                               options={sendToOptions}/>
            </Form.Item>
        </div>
    )
}
